// extracted by mini-css-extract-plugin
export var column = "HorizontalScroll__column__jr7XW";
export var flex = "HorizontalScroll__flex__M_7aW";
export var flexColumn = "HorizontalScroll__flexColumn__hynYa";
export var gap1 = "HorizontalScroll__gap1__uPqDt";
export var gap2 = "HorizontalScroll__gap2__T0Dbe";
export var gap3 = "HorizontalScroll__gap3__itQiV";
export var gap4 = "HorizontalScroll__gap4__hJ36y";
export var gap5 = "HorizontalScroll__gap5__oBfiR";
export var row = "HorizontalScroll__row__pNBAR";
export var wrapper = "HorizontalScroll__wrapper__mOaPB";